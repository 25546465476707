
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonText } from '@ionic/vue';
import HeaderOne from '@/components/HeaderOne.vue';
import MensaCard from '@/components/MensaCard.vue';
//import axios from 'axios';
//import { IGerichte } from "../../types/gerichte";

//const parseString = require('xml2js').parseString;

export default {
  name: 'Mensa',
  components: {  IonHeader, IonToolbar, IonTitle, IonContent, IonPage, HeaderOne, MensaCard, IonText },
  data() {
    return {
      gerichte: null,
      error: false
    }
  },
  mounted() {
    /*axios.get('http://xml.stw-potsdam.de/xmldata/b/xml.php', {timeout: 12000,})
            .then(response => {
              parseString(response.data, (err: string, result: string) => {
                if(err) {
                  this.error = true;
                } else {
                  this.gerichte = result;
                  console.log(result);
                }
              });
            }).catch(error => { console.log(error)})*/
  }
}
