<template>
  <ion-item class="custom-card">
    <ion-grid>
      <ion-row class="ion-align-items-center">
        <ion-col>
          <ion-text class="titel">
            {{ titel }}
          </ion-text><br>
          <ion-text class="beschreibung">
            {{ beschreibung }}
          </ion-text><br>
          <ion-text class="content">
            {{ content }}
          </ion-text>
        </ion-col>
        <ion-col
          size="sm"
          class="ion-justify-content-end"
        >
          <ion-icon :icon="appsOutline" />
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
</template>

<script lang="ts">
    import { IonItem, IonIcon, IonGrid, IonRow, IonCol, IonText } from "@ionic/vue";
    import { appsOutline } from "ionicons/icons";

    export default {
        name: "MensaCard",
        components:{ IonItem, IonIcon, IonGrid, IonRow, IonCol, IonText },
        props:{
          titel:
            { type: String, 
              default: 'Titel'
            },
          beschreibung: {
            type: String,
            default: 'Beschreibung'
          },
          content: {
            type: String,
            default: 'Inhalt'
          },
        },
        setup() {
            return {
                appsOutline,
            }
        }
    }
</script>

<style scoped>
    .custom-card {
        --background: var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff)));
        --color: var(--ion-card-color, var(--ion-item-color, var(--ion-color-step-550, #737373)));
        margin: 10px;
        border-radius: 4px;
        font-size: 14px;
        --offset-top: 56px;
        --offset-bottom: 57px;
        box-sizing: border-box;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    }

    .titel {
        --color: var(--ion-color-step-550, #737373);
        margin: 0px;
        padding: 0px;
        font-size: 14px;
        font-weight: 500;
    }

    .beschreibung {
        --color: var(--ion-color-step-850, #262626);
        margin: 0px;
        padding: 0px;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        display: block;
        position: relative;
        color: var(--color);
        margin-top: 8px;
    }

    .content {

    }
</style>
