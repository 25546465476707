
    import { IonItem, IonIcon, IonGrid, IonRow, IonCol, IonText } from "@ionic/vue";
    import { appsOutline } from "ionicons/icons";

    export default {
        name: "MensaCard",
        components:{ IonItem, IonIcon, IonGrid, IonRow, IonCol, IonText },
        props:{
          titel:
            { type: String, 
              default: 'Titel'
            },
          beschreibung: {
            type: String,
            default: 'Beschreibung'
          },
          content: {
            type: String,
            default: 'Inhalt'
          },
        },
        setup() {
            return {
                appsOutline,
            }
        }
    }
