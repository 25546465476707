<template>
  <ion-page>
    <HeaderOne v-bind:name="$t('canteen')" />
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">
            {{$t('canteen')}}
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <!-- Anzeigen, wenn keine Gerichte verfügbar -->
      <div class="mensa-content" v-if="gerichte === null">
        <img src="/assets/food.svg" alt="Hier ist gerade nix los...">
        <ion-text class="text">{{$t('canteen_error')}}</ion-text>
      </div>

      <!--Gerichte anzeigen sofern verfügbar-->
      <div v-if="gerichte != null">
        <MensaCard
            titel="Angebot 1"
            beschreibung="Ganz leckeres Essen aus der Region"
            content="Preis"
        />
        <MensaCard
            titel="Angebot 1"
            beschreibung="Ganz leckeres Essen aus der Region"
            content="Preis"

        />
        <MensaCard
            v-for="(gericht) in gerichte.menu.datum[0].angebotnr"
            v-bind:key="gericht.titel"
            v-bind:titel="gericht.titel"
            v-bind:beschreibung="gericht.beschreibung"
            content="Preis"
        />
      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonText } from '@ionic/vue';
import HeaderOne from '@/components/HeaderOne.vue';
import MensaCard from '@/components/MensaCard.vue';
//import axios from 'axios';
//import { IGerichte } from "../../types/gerichte";

//const parseString = require('xml2js').parseString;

export default {
  name: 'Mensa',
  components: {  IonHeader, IonToolbar, IonTitle, IonContent, IonPage, HeaderOne, MensaCard, IonText },
  data() {
    return {
      gerichte: null,
      error: false
    }
  },
  mounted() {
    /*axios.get('http://xml.stw-potsdam.de/xmldata/b/xml.php', {timeout: 12000,})
            .then(response => {
              parseString(response.data, (err: string, result: string) => {
                if(err) {
                  this.error = true;
                } else {
                  this.gerichte = result;
                  console.log(result);
                }
              });
            }).catch(error => { console.log(error)})*/
  }
}
</script>

<style scoped>
.mensa-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.mensa-content >  img {
  max-width: 300px;
  height: auto;
  margin: 30px;
}
</style>
